<template>
	<div>
    	<div v-if="!loading">
	        <div class="mb-3">
	            <b-button variant="secondary" @click="scan">
	                <template v-if="scanning">
	                    <font-awesome-icon :icon="['fal', 'spinner']" spin /> {{ $t('global.bluetooth_active') }}
	                </template>
	                <template v-else>
	                    {{ $t('global.activer_bleutooth') }}
	                </template>
	            </b-button>
	        </div>

	        <textarea class="w-100 form-control" rows="15" v-model="text_values" :placeholder="getTrad('horse.transpondeur_sire_par_ligne')"></textarea>
	        
	        <div class="row justify-content-center mt-3">
	        	<div class="col-12 col-md-auto">
	        		<b-form-checkbox id="stallion" v-model="stallion" class="mt-2"><label for="stallion">{{ $t("horse.all_male_reproducteur") }}</label></b-form-checkbox>
	        	</div>
	        	<div class="col-12 text-center mt-3 mt-md-0 col-md-auto ml-md-auto">
		            <b-button variant="primary" class="ml-auto" @click="import_horse">
		                {{ $t('horse.check_list') }} <font-awesome-icon :icon="['fal', 'search']" />
		            </b-button>
		        </div>
	        </div>
	    </div>
        <div v-else class="container-fluid">
        	<div v-for="(value, key) in values" :key="key" class="result_horse_lot" :class="{ 'error': value.error, 'added': value.added }">
        		<div v-if="!display_errors || (display_errors && value.error)" class="row">
        			<div class="col-auto">
	        			<font-awesome-icon v-if="value.error != ''" :icon="['fal', 'exclamation-triangle']"/>
	        			<font-awesome-icon v-else-if="value.added" :icon="['fal', 'check-circle']" />
	        			<font-awesome-icon v-else-if="value.load" :icon="['fal', 'spinner']" spin />
	        		</div>
	        		<div class="col">
	        			{{ value.val }}
	        		</div>
	        		<div class="col-auto">
	        			{{ value.error }}
	        		</div>
        		</div>
        	</div>
        	<div class="row mt-4 justify-content-center">
        		<div class="col-auto">
        			<b-button v-if="horse_errors" variant="secondary" pill @click="toggle_errors" class="error mr-2">{{ horse_errors }} <template v-if="horse_errors == 1">{{ $t('horse.horse_error') }}</template><template v-if="horse_errors > 1">{{ $t('horse.horse_errors') }}</template></b-button>
        			<b-button variant="secondary" pill>{{ horse_added }} <template v-if="horse_added < 2">{{ $t('horse.horse_added') }}</template><template v-if="horse_added > 1">{{ $t('horse.horses_added') }}</template></b-button>
        		</div>
        		<div class="col-auto">
		            <b-button v-if="loading && !done" variant="primary">
		                {{ horse_done }}/{{ horse_total }} {{ $t('horse.horse_analysed') }} <font-awesome-icon :icon="['fal', 'spinner']" spin />
		            </b-button>
		        </div>
		        <div class="col-12 mt-3 mt-md-0 col-md-auto ml-md-auto text-center">
		            <b-button v-if="done" variant="primary" @click="go_to_list">
		                {{ $t('horse.consulte_liste') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
		            </b-button>
		        </div>
        	</div>
        </div>
	</div>
</template>

<script type="text/javascript">
	import Horse from "@/mixins/Horse.js"
	import Common from '@/assets/js/common.js'

	export default {
		name: 'AddHorseLot',
		mixins: [Horse],
		data () {
			return {
	            text_values: '',
	            scanning: false,
				loading: false,
	            device: null,
	            values: [],
	            stallion: false,
	            display_errors: false,
	            done: false,
	            load_list: false
			}
		},

		methods: {
	        scan() {
	            let that = this
	            try {
	                if(that.device != null) {
	                    that.device.gatt.disconnect()
	                    that.device = null
	                    that.scanning = false
	                }

	                navigator.bluetooth.requestDevice({
	                    optionalServices: ['0000ffe0-0000-1000-8000-00805f9b34fb'],
	                    acceptAllDevices: true
	                })
	                .then(device => {
	                    that.device = device
	                    return device.gatt.connect()
	                })
	                .then(server => {
	                    return server.getPrimaryService('0000ffe0-0000-1000-8000-00805f9b34fb')
	                })
	                .then(service => {
	                    return service.getCharacteristic('0000ffe1-0000-1000-8000-00805f9b34fb')
	                })
	                .then(characteristic => {
	                    return characteristic.startNotifications()
	                })
	                .then(characteristic => {
	                    characteristic.addEventListener('characteristicvaluechanged', (event) => {
	                        let enc = new TextDecoder('windows-1258')
	                        enc = enc.decode(event.target.value)
	                        const regex = /([0-9]{3} [0-9]{12})/
	                        let str = null
	                        if((str = regex.exec(enc)) !== null) {
	                            const new_line =  '\r\n'
	                            let temp = that.text_values.trim() + new_line + str[0].replace(' ', '').trim()
	                            that.text_values = temp.trim() + new_line
	                        }
	                    })
	                    that.scanning = true
	                })
	                .catch(error => {
	                    this.failureToast("toast.enable_bluetooth_error")
	                });
	            } catch(e) {
	                this.failureToast("toast.enable_bluetooth_error")
	            }
	        },

	        async import_horse() {
	        	if(this.loading) 
	        		return false
                this.loading = true
                const text_values = this.text_values.replaceAll(' ', '').split(/\r\n|\n|\r/)
                this.values = text_values.map(val => {
                	let type = '', error = ''
                	if(this.regex_sire.test(val)) {
                		type = 'sire'
                	}
                	else if(this.regex_microship.test(val)) {
                		type = 'microship'
                	}
                	else {
                		error = this.getTrad('horse.sire_microship_invalid')
                	}

                	return {
                		val,
                		type,
                		error,
                		done: error,
                		added: false,
                		load: false,
                		horse_id: null
                	}
                })

                const stallion = this.stallion ? 1 : 0

                await Common.asyncForEach(this.values, async (value, key) => {
                	if(!value.error) {
                		this.values[key].load = true
                		const data = await this.addHorse(value.val, value.type, stallion)
                		if(data.horse_id) {
                			this.values[key].added = true
                			this.values[key].horse_id = data.horse_id
                		}
                		else {
                			this.values[key].error = this.getTrad('horse.'+data.code)
                		}
                		this.values[key].done = true
                	}
                })
	        	await this.$sync.force()

                this.done = true
	        },

	        toggle_errors() {
	        	this.display_errors = !this.display_errors
	        },

	        async go_to_list() {
	        	const horse_ids = this.values.filter(val => val.horse_id).map(val => val.horse_id)
	        	this.$router.push({ name: 'AddList', params: { horse_ids }})
	        }
		},

		computed: {
			horse_errors() {
				return this.values.filter(val => val.error).length
			},
			horse_added() {
				return this.values.filter(val => val.added).length
			},
			horse_done() {
				return this.values.filter(val => val.done).length
			},
			horse_total() {
				return this.values.length
			}
		}
	}

</script>